/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Feb 16 2021
 *  File : index.jsx
 *******************************************/

import { useSelector } from 'react-redux';
import authStatuses from '../config/constants/AuthConstants';
import HomeNotAuthorized from './home/Home';

export default function Home() {
    const {
        authStatus,
    } = useSelector((state) => state.authReducer);

    if(authStatus === authStatuses.LOGGED_OUT){
        return <HomeNotAuthorized isLoggedIn={false} />;
    }else{
        // when user is loggedIn
        return <HomeNotAuthorized isLoggedIn={true} />;
    }
}